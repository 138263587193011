import React from 'react';
import './PatientsBox.css'

const PatientsBox = () => {
    return (
        <>
            <h3>COSMETIC DERMATOLOGY</h3>
            <p>
            Our skin care professionals offer a broad range of cosmetic services such as injectable, lasers, pharmaceutical grade skin care products and esthetician services with the latest technology to enhance and maintain your skin's health and appearance. Other treatments are:</p>
            <ul>
                <li>Botox</li>
                <li>Wrinkle Prevention</li>
                <li>Laser Treatments</li>
                <li>Fillers</li>
                <li>Chemical Peels</li>
                <li>Varicose and Spider Veins</li>
                <li>Esthetician Services</li>
            </ul>
            <p>
            It is best if you come in for a cosmetic consultation so we can recommend treatments that will best address your needs and concerns. We will also provide a cost analysis for your consideration.
            </p>
            <h3>CLINICAL DERMATOLOGY</h3>
            <p>
            Because our skin is constantly changing, we can develop moles, pre-cancers or encounter acne, eczema, psoriasis, or rosacea breakouts at any time. Fortunately, we treat all skin conditions at all ages using pharmaceuticals, lasers, surgery or a combination of these methods to alleviate or eliminate most of your dermatological concerns.
            </p>
            <h3>SURGICAL DERMATOLOGY</h3>
            <span className="surgical-box">
            <span className="surgical-box-1">
                <p>
                Dermatologic surgery deals with the diagnosis and treatment of medically necessary and cosmetic conditions of the skin, hair, nails, veins, mucous membranes and adjacent tissues by various surgical, reconstructive, cosmetic and non-surgical methods. The purpose of dermatologic surgery is to repair and/or improve the function and cosmetic appearance of skin tissue.

                Thanks to research breakthroughs, clinical advances and instrumentation innovations pioneered by skin surgery experts, the majority of dermatologic surgeries are now minimally invasive and require only local or regional anesthesia. This translates into superior patient safety while reducing the all-important "downtime" and recovery period. In addition, new developments and the latest techniques are constantly enhancing the results of many established skin surgery procedures.  
                </p>
                </span>
            <span className="surgical-box-2">
                <h4>What is dermatologic surgery?</h4>
                <p>Dermatologic surgery is the practice of dermatology that specializes in surgical procedures and minimally invasive treatments to improve the health, function and appearance of your skin.</p>

                <h4>Who is a dermatologic surgeon?</h4>
                <p>A dermatologic surgeon is a board-certified physician who specializes in skin surgery.Their primary focus is on the health and beauty of your skin – from head to toe. Nearly all dermatologic surgeons perform both medically necessary and cosmetic surgery procedures to maintain the health, function and appearance of your skin.
                Dermatologic surgeons are pioneers in the research and development of many of today's most effective therapeutic and cosmetic skin care solutions. For example, the art and science of procedures for the treatment of skin cancer, aging and sun-damaged skin, unwanted hair, varicose veins, scars, undesirable body fat, hair loss and more were investigated and refined by dermatologic surgeons.
                </p>
            </span>
            </span>
            <h3>What Is Mohs Surgery?</h3>
            <p>Mohs surgery, named after Frederick Mohs, is the most effective technique available in treating skin cancer. Performed in an outpatient procedure, Mohs surgery entirely removes the affected skin cancer area while sparing the surrounding healthy skin.</p>
            <h3>When Is Mohs Surgery Appropriate?</h3>
            <p>Mohs surgery is commonly used to remove large skin cancers, skin cancers in hard-to-reach places, recurrent skin cancers, and those occurring in areas where preservation of the skin is essential (such as the tissue surrounding the eyes and nose). This procedure can be performed on basal cell or squamous cell carcinomas. It may also be useful for melanoma.</p>
        </>
    )
}

export default PatientsBox;