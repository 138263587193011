import React from 'react';
import Typography from '@material-ui/core/Typography';

const Heading = (props) => {
    return (
        <Typography variant="h4" component="h1" gutterBottom>
            {props.children}
        </Typography>
    )
}

export default Heading;
